const authReducer = (state, { type, payload }) => {
    switch (type) {
        case 'LOGIN':
            return state;

        default:
            return state;
    }
};

export default authReducer;
