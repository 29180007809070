import React, { useState } from 'react';
import { Image, Platform, StyleSheet, View } from 'react-native';
import { Button, Icon } from 'native-base';

import Sound from '../services/Sound';
import { PhoneCall as PhoneCallIcon } from 'react-native-feather';

import Text from '../components/Text';
import Dialpad from '../components/dialpad';

import Colors from '../assets/Colors';
import { AUTHENTICATE } from '../constants/routeNames';

import appConfig from '../config';

export default function Home({ navigation }) {

    const defaultNumber = [
        '...what?', ':|:...a number?', ':|::|&|:', ':|&|:', ':|:#rofl #oldskool',
        '', '', '', ':|:'
    ];

    const [theWarning, setTheWarning] = useState(defaultNumber);
    const [dialledNumber, setDialledNumber] = useState('');

    const clearNumber = () => {
        setTheWarning(defaultNumber);
        setDialledNumber('');
    };

    const showNumber = (buttonNumber) => {
        // disabled for a joke (celebrating first release)
        //setDialledNumber(dialledNumber + number);

        if (theWarning.length < 1) {
            // ... they're still trying to type after all the warnings!
            setDialledNumber('One moment please...');

            if (Platform.OS === 'android') {
                // play the "please wait" audio
                Sound.setCategory('Playback');
                var pleaseWaitSound = new Sound('privacy_unident.wav', Sound.MAIN_BUNDLE, (error) => {
                    if (error) {
                        // console.log(error);
                        return;
                    } else {
                        pleaseWaitSound.setVolume(0.04);
                        pleaseWaitSound.play((success) => {
                            if (success) {
                                // console.log('playback success');
                            } else {
                                // console.log('playback error', error);
                            }
                        });
                    }
                });
            }

            setTimeout(() => { setDialledNumber(''); navigation.navigate(AUTHENTICATE); }, 1684);
            return;
        }

        let word = theWarning.shift();
        setTheWarning(theWarning);

        let currentDisplay = dialledNumber;

        if (word.substr(0, 3) === ':|:') {
            // make blank the current displayed number
            currentDisplay = '';
            word = word.substr(3);
        }

        // allow the number to be displayed by reference in the warning
        word = word.replace(/:\|&\|:/, buttonNumber);

        setDialledNumber(currentDisplay + word);

    }

    return (
        <View style={styles.screenContainer}>
            <View style={styles.profileContainer}>
                <Image style={styles.profileImage}
                    source={require('./assets/profile_photo.jpg')}
                />
                <View style={styles.profileNameContainer}>
                    <Text style={styles.profileName}>
                        {appConfig.owner.profileFullName}
                    </Text>
                    <Text style={styles.profileSubtitle}>
                        {appConfig.owner.profileSubtitle}
                    </Text>
                </View>
            </View>
            <View style={styles.dialledNumberContainer}>
                <Text style={styles.dialledNumber}>
                    {dialledNumber}
                </Text>
                <View style={styles.clearDialledNumberContainer}>
                    {dialledNumber.length > 0 &&
                        <Icon style={styles.clearDialledNumber}
                            onPress={clearNumber}
                            name="close"
                        />
                    }
                </View>
            </View>
            <View style={styles.dialpadContainer}>
                <Dialpad onPress={showNumber} sizing={1} spacing={2} />
            </View>
            <View style={styles.callButtonContainer}>
                <Button large primary rounded style={styles.callButton}
                    onPress={() => navigation.navigate(AUTHENTICATE)}>
                    <PhoneCallIcon width={50} height={50} style={styles.callButtonIcon} />
                </Button>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    screenContainer: {
        height: '100%',
        backgroundColor: Colors.antzcodeBackgroundBlue,
    },
    screenContainerBackgroundImage: {
        width: '100%',
        height: '100%',
    },
    profileContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'row',
        marginTop: 10,
        paddingTop: 30,
        paddingBottom: 40,
    },
    profileImage: {
        width: 150,
        height: 150,
        marginTop: -20,
        marginBottom: -40,
        marginLeft: 10,
        marginRight: 0,
        borderColor: Colors.antzcodeWhite,
        borderWidth: 1,
        borderRadius: 150,
        zIndex: 2,
    },
    profileNameContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: 110,
        marginLeft: -30,
        paddingLeft: 30,
        paddingRight: 20,
        backgroundColor: Colors.antzcodeDarkBlue,
        borderColor: Colors.antzcodeWhite,
        borderWidth: 1,
        zIndex: 1,
    },
    profileName: {
        textAlign: 'left',
        fontSize: 30,
        marginTop: 18,
        marginLeft: 10,
        color: Colors.antzcodeWhite,
    },
    profileSubtitle: {
        textAlign: 'left',
        fontSize: 15,
        fontStyle: 'italic',
        marginTop: 0,
        marginLeft: 25,
        color: Colors.antzcodeWhite,
    },
    dialledNumberContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        margin: 0,
        marginTop: -20,
        marginBottom: 10,
        padding: 0,
    },
    dialledNumber: {
        flex: 1,
        textAlign: 'center',
        fontSize: 40,
        minHeight: 50,
        marginTop: 0,
        marginBottom: 0,
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 0,
        paddingBottom: 0,
        color: Colors.antzcodeWhite
    },
    clearDialledNumberContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        paddingRight: 10,
    },
    clearDialledNumber: {
        flex: 0,
        width: 40,
        height: 40,
        textAlign: 'center',
        textAlignVertical: 'center',
        borderRadius: 20,
        backgroundColor: 'gray',
    },
    dialpadContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: 'row',
    },
    callButtonContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: 'row',
        margin: 30
    },
    callButton: {
        height: 80,
        width: 80,
        padding: 12,
        backgroundColor: 'green',
        borderRadius: 50,
    },
    callButtonIcon: {
        width: 50,
        height: 50,
        color: Colors.antzcodeWhite,
    }
});
