import React, { useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import {
    Container,
    Content,
    Form,
    Item,
    Input,
    Textarea,
    Picker,
    Button,
} from 'native-base';

import { Send as SendIcon } from 'react-native-feather';

import Text from '../components/Text';

import Colors from '../assets/Colors';

export default function Authenticate() {

    const errors = {
        name: false,
        company: false,
        email: false,
        phone: false,
        purpose: false,
        purposeType: false,
    };

    const [enableValidation, setEnableValidation] = useState(false);

    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [purpose, setPurpose] = useState('');
    const [purposeType, setPurposeType] = useState('');

    const [nameError, setNameError] = useState(false);
    const [companyError, setCompanyError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [purposeError, setPurposeError] = useState(false);
    const [purposeTypeError, setPurposeTypeError] = useState(false);

    const validations = {
        name: () => {
            if (name.length < 1 || name.split(' ').length < 2) {
                errors.name = true;
            } else {
                errors.name = false;
            }
            setNameError(errors.name);
        },
        email: () => {
            if (email.trim().length < 6) {
                errors.email = true;
            } else {
                errors.email = false;
            }
            setEmailError(errors.email);
        },
        company: () => {
            // optional
            errors.company = false;
            setCompanyError(errors.company);
        },
        phone: () => {
            if (phone.trim().length < 8) {
                errors.phone = true;
            } else {
                errors.phone = false;
            }
            setPhoneError(errors.phone);
        },
        purposeType: () => {
            if (['personal', 'spam', 'legal'].indexOf(purposeType) < 0) {
                errors.purposeType = true;
            } else {
                errors.purposeType = false;
            }
            setPurposeTypeError(errors.purposeType);
        },
        purpose: () => {
            if (['spam', 'legal'].indexOf(purposeType) > -1) {
                // requires explanation when for a spam or legal purpose
                if (purpose.trim().length < 30) {
                    errors.purpose = true;
                } else {
                    errors.purpose = false;
                }
                setPurposeError(errors.purpose);
            } else {
                errors.purpose = false;
                setPurposeError(errors.purpose);
            }
        },
    };

    const validate = (field) => {
        if (!enableValidation) {
            return;
        }
        switch (field) {
            case 'name':
                validations.name();
                break;
            case 'company':
                validations.company();
                break;
            case 'phone':
                validations.phone();
                break;
            case 'email':
                validations.email();
                break;
            case 'purposeType':
                validations.purposeType();
                break;
            case 'purpose':
                validations.purpose();
                break;
        }
    };

    useEffect(() => {
        validate('name');
    }, [name]);

    useEffect(() => {
        validate('company');
    }, [company]);

    useEffect(() => {
        validate('email');
    }, [email]);

    useEffect(() => {
        validate('phone');
    }, [phone]);

    useEffect(() => {
        validate('purposeType');
    }, [purposeType]);

    useEffect(() => {
        validate('purpose');
    }, [purpose]);

    useEffect(() => {
        setEnableValidation(true);
    }, []);

    const submitForm = () => {
        validate('name');
        validate('company');
        validate('phone');
        validate('email');
        validate('purposeType');
        validate('purpose');

        if (errors.name || errors.company || errors.phone || errors.email || errors.purposeType || errors.purpose) {
            alert('You must first correct the errors in the form');
        } else {
            alert('Ok to submit: ' + name + ', ' + company + ', ' + email + ', ' + phone + ', ' + purposeType + ', ' + purpose);
        }

    };

    return (
        <Container style={styles.screenContainer}>
            <Content>
                <Form style={styles.form}>
                    <Item error={nameError} style={styles.formItem}>
                        <Input
                            placeholder="Name"
                            value={name}
                            style={styles.textInputField}
                            onChangeText={(text) => setName(text)}
                            onBlur={() => validate('name')}
                        />
                        {nameError &&
                            <Text style={styles.formErrorMessage}>Full name required</Text>
                        }
                    </Item>
                    <Item error={companyError} style={styles.formItem}>
                        <Input
                            placeholder="Company:"
                            value={company}
                            style={styles.textInputField}
                            onChangeText={(text) => setCompany(text)}
                            onBlur={() => validate('company')}
                        />
                        {companyError &&
                            <Text style={styles.formErrorMessage}>required</Text>
                        }
                    </Item>
                    <Item error={emailError} style={styles.formItem}>
                        <Input
                            placeholder="Email:"
                            value={email}
                            style={styles.textInputField}
                            onChangeText={(text) => setEmail(text)}
                            onBlur={() => validate('email')}
                        />
                        {emailError &&
                            <Text style={styles.formErrorMessage}>required</Text>
                        }
                    </Item>
                    <Item error={phoneError} style={styles.formItem}>
                        <Input
                            placeholder="Phone Number/VoIP Service &amp; ID:"
                            value={phone}
                            style={styles.textInputField}
                            onChangeText={(text) => setPhone(text)}
                            onBlur={() => validate('phone')}
                        />
                        {phoneError &&
                            <Text style={styles.formErrorMessage}>required</Text>
                        }
                    </Item>
                    <Item error={purposeTypeError} style={styles.formItem}>
                        <Picker
                            placeholder="Relationship:"
                            selectedValue={purposeType}
                            mode="dropdown"
                            style={styles.pickerInputField}
                            iosIcon={'<Icon name="arrow-down" />'}
                            icon={'<Icon name="home" />'}
                            itemStyle={{
                                backgroundColor: '#03d3d3',
                                marginLeft: 0,
                                paddingLeft: 10,
                            }}
                            itemTextStyle={{ color: '#088ad2' }}
                            onValueChange={(value) => { setPurposeType(value) }}
                        >
                            <Picker.Item label="Personal/Business" value="personal" />
                            <Picker.Item label="Spam/Cold-Call Opportunity" value="spam" />
                            <Picker.Item label="Official/Legal" value="legal" />
                        </Picker>
                        {purposeTypeError &&
                            <Text style={styles.formErrorMessage}>required</Text>
                        }
                    </Item>
                    {['spam', 'legal'].indexOf(purposeType) > -1 &&
                        <Item error={purposeError} style={styles.formItem}>
                            <Textarea
                                placeholder="State your purpose and Address for Service:"
                                value={purpose}
                                rowSpan={5}
                                bordered
                                style={styles.textAreaInputField}
                                onChangeText={(text) => setPurpose(text)}
                                onBlur={() => validate('purpose')}
                            />
                            {purposeError &&
                                <Text style={styles.formErrorMessage}>required</Text>
                            }
                        </Item>
                    }
                    <View style={styles.formButtonContainer}>
                        <Button style={styles.formButton} iconLeft onPress={() => submitForm()}>
                            <SendIcon style={styles.formButtonSendIcon} />
                            <Text style={styles.formButtonSendText}>Send Request</Text>
                        </Button>
                    </View>

                </Form>
            </Content>
        </Container >
    );
}

const styles = StyleSheet.create({
    screenContainer: {
        height: '100%',
        backgroundColor: Colors.antzcodeBackgroundBlue,
    },
    form: {
        margin: 10,
    },
    formItem: {
        marginBottom: 20,
    },
    textInputField: {
        width: '100%',
        backgroundColor: Colors.antzcodeWhite,
    },
    pickerInputField: {
        height: 50,
        backgroundColor: Colors.antzcodeWhite,
    },
    textAreaInputField: {
        width: '100%',
        backgroundColor: Colors.antzcodeWhite,
    },
    formErrorMessage: {
        marginLeft: 20,
        color: Colors.antzcodeWhite,
    },
    formButtonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        marginTop: 30,
    },
    formButton: {
        backgroundColor: Colors.antzcodeBrightBlue
    },
    formButtonSendIcon: {
        width: 20,
        height: 20,
        marginLeft: 10,
        marginRight: -10,
        color: Colors.antzcodeWhite
    },
    formButtonSendText: {
        fontSize: 20,
        color: Colors.antzcodeWhite,
    },
});
