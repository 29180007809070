import React from 'react';
import { StyleSheet } from 'react-native';
import { Text as SysText } from 'native-base';

const Text = ({ children, style }) => {
    const styles = StyleSheet.create({
        text: {
            fontFamily: 'Roboto_500Medium',
        }
    });
    return (<SysText style={[styles.text, style]}>{children}</SysText>);
};

export default Text;