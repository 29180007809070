import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { createAppContainer } from 'react-navigation';
import { createStackNavigator } from 'react-navigation-stack';

import { Header, Icon, Title } from 'native-base';

import Home from '../screens/home';
import Authenticate from '../screens/authenticate';


import Text from '../components/Text';
import Colors from '../assets/Colors';
import { Bluetooth } from 'react-native-feather';

const screens = {
    Home: {
        screen: Home,
        navigationOptions: () => ({
            header: () => {
                return (
                    <Header style={styles.header}>
                        <Title style={styles.headerTitle}><View style={styles.headerTitleTextWrapper}><Text style={styles.headerTitleText}>NaN Phone v3.2</Text></View></Title>
                        <Title style={styles.headerSubtitle}><View style={styles.headerSubtitleTextWrapper}><Text style={styles.headerSubtitleText}> (Not a Number phone)</Text></View></Title>
                    </Header >)
            }
        })
    },
    Authenticate: {
        screen: Authenticate,
        navigationOptions: (navigation) => ({
            header: ({ navigation }) => {
                return (
                    <Header style={styles.header}>

                        <TouchableOpacity
                            style={styles.headerBackButton}
                            onPress={() => { navigation.goBack() }}
                        >
                            <View style={styles.headerBackButtonIconWrapper}>
                                <Icon name={'arrow-back'}
                                    size={30}
                                    style={styles.headerBackButtonIcon}
                                />
                            </View>
                        </TouchableOpacity>

                        <Title style={styles.headerTitle}><View style={styles.headerTitleTextWrapper}><Text style={styles.headerTitleText}>Request Authorization</Text></View></Title>
                    </Header >)
            }
        })
    }
};

const styles = StyleSheet.create({
    header: {
        display: 'flex',
        justifyContent: 'flex-start',
        textAlign: 'left',
        height: 50,
        backgroundColor: Colors.antzcodeDarkBlue,
    },
    headerTitle: {
    },
    headerTitleTextWrapper: {
        display: 'flex',
        justifyContent: 'space-around',
        height: 50,
    },
    headerTitleText: {
        color: Colors.antzcodeWhite,
    },
    headerSubtitle: {
        marginLeft: 10,
    },
    headerSubtitleTextWrapper: {
        display: 'flex',
        justifyContent: 'space-around',
        height: 50,
    },
    headerSubtitleText: {
        fontSize: 16,
        color: Colors.antzcodeWhite,
    },
    headerBackButton: {
        marginLeft: 10,
        marginRight: 10,
    },
    headerBackButtonIconWrapper: {
        display: 'flex',
        justifyContent: 'space-around',
        height: 50,
    },
    headerBackButtonIcon: {
        color: Colors.antzcodeWhite,
    },
});

const UnauthorizedStack = createStackNavigator(screens);

const UnauthorizedNavigator = createAppContainer(UnauthorizedStack);

export default UnauthorizedNavigator;
