class Dtmf {

    DTMF_0 = '0';
    DTMF_1 = '1';
    DTMF_2 = '2';
    DTMF_3 = '3';
    DTMF_4 = '4';
    DTMF_5 = '5';
    DTMF_6 = '6';
    DTMF_7 = '7';
    DTMF_8 = '8';
    DTMF_9 = '9';
    DTMF_S = 'S';
    DTMF_H = 'H';

    startTone = () => { };
    stopTone = () => { };
}

const dtmf = new Dtmf;

export default dtmf;