import React, { createContext, useReducer } from "react";

import authReducer from './reducers/auth';
import configReducer from './reducers/config';
import initialAuthState from './initialStates/auth';
import initialConfigState from './initialStates/config';

export const GlobalContext = createContext({});

const GlobalProvider = ({ children }) => {

    const [authState, authDispatch] = useReducer(authReducer, initialAuthState);
    const [configState, configDispatch] = useReducer(configReducer, initialConfigState);

    const contextStates = {
        authState, authDispatch,
        configState, configDispatch,
    };

    return (<GlobalContext.Provider value={contextStates}>{children}</GlobalContext.Provider>);
};

export default GlobalProvider;
