import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Button } from 'native-base';

import dtmf from '../services/Dtmf';

import Text from './Text';

import Colors from '../assets/Colors';

/**
 * Component generates a phone dialpad button matrix. When buttons are pressed,
 *   the industry-standard DTMF sound is played.
 * onPress is an optional callback that is fired with the button number that is pressed.
 * sizing and spacing enables the dialpad to be adjusted by a multiplying factor
 * 
 * Eg: <Dialpad sizing={0.8} spacing={1.5} onPress={(btnNumber) => {this.state.dialledNumber.push(btnNumber)}} />
 * 
 * @param {any} { onPress, sizing, spacing } 
 * @returns 
 */
const Dialpad = ({ onPress, sizing, spacing }) => {

    // how long does the tone play?
    const duration = 265;

    // how long is the pause between one tone to the next?
    const pause = 30;

    // do not permit a tone to play when another tone is already playing
    const [isPlaying, setIsPlaying] = useState(false);

    // master queue is mutable to reduce code race errors in FIFO queue
    const [masterDialledQueue, setMasterDialledQueue] = useState([]);

    // read-only queue is copied from master queue and immutable for triggering useEffect hook 
    const [dialledQueue, setDialledQueue] = useState([]);

    // register a hook to listen for changes to dialledQueue and invoke playback of DTMF tone
    useEffect(() => {
        processDialledQueue();
    }, [dialledQueue]);

    // @Public method to add a button press to the FIFO queue for playback
    const pressButton = (button) => {
        masterDialledQueue.push(button);
        setDialledQueue([...masterDialledQueue]);
        onPress(button);
    };

    // @Private
    const processDialledQueue = () => {
        // do nothing if already playing or if queue is empty
        if (isPlaying) { return; }
        if (dialledQueue.length < 1) { return; }

        // prevent other sounds playing at the same time
        setIsPlaying(true);

        // play the FIFO sound
        playButtonSound(dialledQueue[0]);

        setTimeout(() => {
            // remove the first sound in the queue and make way for the next sound to play
            masterDialledQueue.shift();
            setIsPlaying(false);
            setDialledQueue([...masterDialledQueue]);
        }, (duration + pause));
    };

    const playButtonSound = (button) => {
        
        switch (button) {
            case '1':
                dtmf.startTone(dtmf.DTMF_1);
                break;

            case '2':
                dtmf.startTone(dtmf.DTMF_2);
                break;

            case '3':
                dtmf.startTone(dtmf.DTMF_3);
                break;

            case '4':
                dtmf.startTone(dtmf.DTMF_4);
                break;

            case '5':
                dtmf.startTone(dtmf.DTMF_5);
                break;

            case '6':
                dtmf.startTone(dtmf.DTMF_6);
                break;

            case '7':
                dtmf.startTone(dtmf.DTMF_7);
                break;

            case '8':
                dtmf.startTone(dtmf.DTMF_8);
                break;

            case '9':
                dtmf.startTone(dtmf.DTMF_9);
                break;

            case '*':
                dtmf.startTone(dtmf.DTMF_S);
                break;

            case '0':
                dtmf.startTone(dtmf.DTMF_0);
                break;

            case '#':
                dtmf.startTone(dtmf.DTMF_P);
                break;

            default:
                return;
        }

        // stop the tone when it has been heard long enough
        setTimeout(() => {
            dtmf.stopTone();
        }, duration);

    };

    // the size and spacing of the dialpad buttons can be adjusted by a multipling factor
    const styles = getStyles((sizing || 1), (spacing || 1));

    return (
        <View style={styles.dialpad}>
            <View style={styles.row}>
                <Button style={styles.button} onPress={() => pressButton('1')}>
                    <Text style={styles.buttonNumber, styles.buttonNumberNoLetters}>1</Text>
                </Button>
                <Button style={styles.button} onPress={() => pressButton('2')}>
                    <Text style={styles.buttonNumber}>2</Text>
                    <Text style={styles.buttonLetters}>ABC</Text>
                </Button>
                <Button style={styles.button} onPress={() => pressButton('3')}>
                    <Text style={styles.buttonNumber}>3</Text>
                    <Text style={styles.buttonLetters}>DEF</Text>
                </Button>
            </View>
            <View style={styles.row}>
                <Button style={styles.button} onPress={() => pressButton('4')}>
                    <Text style={styles.buttonNumber}>4</Text>
                    <Text style={styles.buttonLetters}>GHI</Text>
                </Button>
                <Button style={styles.button} onPress={() => pressButton('5')}>
                    <Text style={styles.buttonNumber}>5</Text>
                    <Text style={styles.buttonLetters}>JKL</Text>
                </Button>
                <Button style={styles.button} onPress={() => pressButton('6')}>
                    <Text style={styles.buttonNumber}>6</Text>
                    <Text style={styles.buttonLetters}>MNO</Text>
                </Button>
            </View>
            <View style={styles.row}>
                <Button style={styles.button} onPress={() => pressButton('7')}>
                    <Text style={styles.buttonNumber}>7</Text>
                    <Text style={styles.buttonLetters}>PQRS</Text>
                </Button>
                <Button style={styles.button} onPress={() => pressButton('8')}>
                    <Text style={styles.buttonNumber}>8</Text>
                    <Text style={styles.buttonLetters}>TUV</Text>
                </Button>
                <Button style={styles.button} onPress={() => pressButton('9')}>
                    <Text style={styles.buttonNumber}>9</Text>
                    <Text style={styles.buttonLetters}>WXYZ</Text>
                </Button>
            </View>
            <View style={styles.row}>
                <Button style={styles.button} onPress={() => pressButton('*')}>
                    <Text style={styles.buttonNumberNoLetters}>*</Text>
                </Button>
                <Button style={styles.button} onPress={() => pressButton('0')}>
                    <Text style={styles.buttonNumber}>0</Text>
                    <Text style={styles.buttonLetters}>+</Text>
                </Button>
                <Button style={styles.button} onPress={() => pressButton('#')}>
                    <Text style={styles.buttonNumberNoLetters}>#</Text>
                </Button>
            </View>
        </View>
    );
};

const getStyles = (sizeMultiplier, spaceMultiplier) => {
    const spacing = 5;
    const buttonSize = 70;
    const buttonNumberSize = 44;
    const buttonLetterSize = 15;

    const sz = (size) => {
        return (size * sizeMultiplier);
    };

    const sp = (space) => {
        return (space * spaceMultiplier);
    };

    return StyleSheet.create({
        dialpad: {
            width: sz(buttonSize * 3 + sp(spacing * 2)),
            height: sz(buttonSize * 4 + sp(spacing * 3)),
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: sz(buttonSize * 3 + sp(spacing * 2)),
            height: sz(buttonSize),
            marginBottom: sp(spacing),
        },
        button: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            width: sz(buttonSize),
            height: sz(buttonSize),
            backgroundColor: Colors.antzcodeDarkBlue,
            borderColor: Colors.antzcodeBorderBlue,
            borderWidth: 1,
        },
        buttonNumber: {
            textAlign: 'center',
            fontSize: sz(buttonNumberSize),
            color: Colors.antzcodeWhite,
            marginTop: sz(-18),
            paddingLeft: sz(0),
            paddingRight: sz(0),
        },
        buttonNumberNoLetters: {
            textAlign: 'center',
            fontSize: sz(buttonNumberSize),
            color: Colors.antzcodeWhite,
            marginTop: sz(-18),
        },
        buttonLetters: {
            position: 'absolute',
            textAlign: 'center',
            fontSize: sz(buttonLetterSize),
            width: sz(buttonSize),
            color: Colors.antzcodeWhite,
            bottom: sz(2),
            paddingLeft: sz(0),
            paddingRight: sz(0),
        }
    });
};

export default Dialpad;